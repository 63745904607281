import React, {useState} from 'react';
import '../assets/scss/pages/pricing.scss';
import { Row, Col } from 'antd';
import {Button} from '../components/common/buttons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CompanyInfo} from '../components/common/companyInfo';

export default function Pricing() {

    const initialState = {
        monthlyBool: true,
        yearlyBool: true,
        totalPrice : 0,
        jobCandidates: {
            type: 'jobCandidates',
            priceType: null,
            price: 0,
            selected: false
        },
        employPerformance: {
            type: 'employPerformance',
            priceType: null,
            price: 0,
            selected: false
        },
        partnerPerformance: {
            type: 'partnerPerformance',
            priceType: null,
            price: 0,
            selected: false
        }
    };

    const [state, setState] = useState(initialState)

    const [priceVal, setPriceVal] = useState({
        jobPrice: {
            monthlyPrice: 200,
            yearlyPrice: 2200
        },
        employeePrice: {
            monthlyPrice: 50,
            yearlyPrice: 500
        },
        partnerPrice: {
            monthlyPrice: 50,
            yearlyPrice: 500
        }
    })

    const [smallSize, setSmallSize] = useState(true);
    const [mediumSize, setMediumSize] = useState(false);
    const [enterpriseSize, setEnterpriseSize] = useState(false);
    const [headings, setHeadings] = useState('Small Business - ');
    const [test, setTest] = useState(true);

    const isPatternValid = (targetPriceType) => {
        return (
          (state.jobCandidates.priceType === targetPriceType ||
            state.jobCandidates.priceType === null) &&
          (state.employPerformance.priceType === targetPriceType ||
            state.employPerformance.priceType === null) &&
          (state.partnerPerformance.priceType === targetPriceType ||
            state.partnerPerformance.priceType === null)
        );
    }

    const handleChange = ({
        price,
        type,
        cardType,
    }) => {

        let total = state?.totalPrice
        const checkType = isPatternValid(type)
        setState((state) => ({
            ...state,
            monthlyBool: type==='monthly' ? true : false,
            yearlyBool: type==='yearly' ? true : false,
        }))
        if(checkType === true){
            if(state?.jobCandidates?.type === cardType){
                if(state?.jobCandidates?.selected === false){
                    total=total+price
                    setState((state) => ({
                        ...state,
                        totalPrice : total,
                        jobCandidates : {
                            ...state?.jobCandidates,
                            priceType: type,
                            price : price,
                            selected: true,
                        }
                    }))
                } else{
                    total=total-price
                    setState((state) => ({
                        ...state,
                        yearlyBool : type==='monthly' ? !state?.employPerformance?.selected && !state?.partnerPerformance?.selected ? true : false : true,
                        monthlyBool : type==='yearly' ? !state?.employPerformance?.selected && !state?.partnerPerformance?.selected ? true : false : true,
                        totalPrice : total,
                        jobCandidates : {
                            ...state?.jobCandidates,
                            priceType: null,
                            selected: false,
                        }
                    }))
                }
            }

            if(state?.employPerformance?.type === cardType){
                if(state?.employPerformance?.selected === false){
                    total=total+price
                    setState((state) => ({
                        ...state,
                        totalPrice : total,
                        employPerformance : {
                            ...state?.employPerformance,
                            priceType: type,
                            price : price,
                            selected: true,
                        }
                    }))
                } else{
                    total=total-price
                    setState((state) => ({
                        ...state,
                        yearlyBool : type==='monthly' ? !state?.jobCandidates?.selected && !state?.partnerPerformance?.selected ? true : false : true,
                        monthlyBool : type==='yearly' ? !state?.jobCandidates?.selected && !state?.partnerPerformance?.selected ? true : false : true,
                        totalPrice : total,
                        employPerformance : {
                            ...state?.employPerformance,
                            priceType: null,
                            selected: false,
                        }
                    }))
                }
            }

            if(state?.partnerPerformance?.type === cardType){
                if(state?.partnerPerformance?.selected === false){
                    total=total+price
                    setState((state) => ({
                        ...state,
                        totalPrice : total,
                        partnerPerformance : {
                            ...state?.partnerPerformance,
                            priceType: type,
                            price : price,
                            selected: true,
                        }
                    }))
                } else{
                    total=total-price
                    setState((state) => ({
                        ...state,
                        totalPrice : total,
                        yearlyBool : type==='monthly' ? !state?.employPerformance?.selected && !state?.jobCandidates?.selected ? true : false : true,
                        monthlyBool : type==='yearly' ? !state?.employPerformance?.selected && !state?.jobCandidates?.selected ? true : false : true,
                        partnerPerformance : {
                            ...state?.partnerPerformance,
                            priceType: null,
                            selected: false,
                        }
                    }))
                }
            }
        } else {
            toast.error('Please select a specific pricing. It should be either monthly or yearly.');
        }
    }

    const handleRadioClick = (event) => {
        const selectedValue = event.target.id;
        setHeadings(event.target.value)
        setSmallSize(selectedValue==='small' ? true : false)
        setMediumSize(selectedValue==='medium' ? true : false)
        setEnterpriseSize(selectedValue==='enterprise' ? true : false)
        setState(initialState);
        setPriceVal((priceVal) => ({
            ...priceVal,
            jobPrice : {
                monthlyPrice: selectedValue==='small' ? 200 : selectedValue==='medium' ? 300 : 400,
                yearlyPrice : selectedValue==='small' ? 2200 : selectedValue==='medium' ? 4200 : 4300,
            },
            employeePrice: {
                monthlyPrice: selectedValue==='small' ? 50 : selectedValue==='medium' ? 100 : 300,
                yearlyPrice: selectedValue==='small' ? 500 : selectedValue==='medium' ? 1000 : 3200,
            },
            partnerPrice: {
                monthlyPrice: selectedValue==='small' ? 50 : selectedValue==='medium' ? 100 : 300,
                yearlyPrice: selectedValue==='small' ? 500 : selectedValue==='medium' ? 1000 : 3200,
            }
        }))
      };

    return <div className="pricing-wrapper">
        <h2 className='heading-2'>
            Simple Pricing for Everyone
        </h2>
        <p className='paragraph'>
            Pricing built for businesses of all sizes. Always know what you’ll pay.
            <br/>
            <b className='trial-font-size'>Free Trial period: 2 months</b>
        </p>
        <div className='radio-button-section'>
            <div className='radio-value'>
                <input type="radio" id="small" name="fav_language" checked={smallSize ? true : false} value="Small Business - " onClick={handleRadioClick}></input>
                <label for="html" value=''>Small Business&lt;20 Employees</label>
            </div>
            <div className='radio-value'>
                <input type="radio" id="medium" name="fav_language" value="Medium Business - " onClick={handleRadioClick}></input>
                <label for="html">Medium Business &lt;=100 Employees</label>
            </div>
            <div className='radio-value'>
                <input type="radio" id="large" name="fav_language" value="Large Business - " onClick={handleRadioClick}></input>
                <label for="html">Large Business &lt;= 1000 Employees</label>
            </div>
            <div className='radio-value'>
                <input type="radio" id="enterprise" name="fav_language" value="Large Enterprise - " onClick={handleRadioClick}></input>
                <label for="html">Large Enterprise > 1000 Employees</label>
            </div>
        </div>
        <div className='pricing-cards-wrapper'>
            <Row>
                <Col xs={24} sm={24} md={12} xl={8}>
                    <div className="pricing-card-wrapper" style={{ boxShadow: state?.jobCandidates?.selected ? '0px 12px 24px 0px #00000029, 0 0 0 2px #FFFFFF, 0 0 0 4px #29AAE2' : '0px 12px 24px 0px #00000029' }}>
                        <div className='plan'>
                            Job Candidates
                        </div>
                        <div className='list'>
                        {
                          enterpriseSize &&
                          <div className='card-details'>
                              Large Enterprise - Custom
                          </div>
                        }
                        {
                          !enterpriseSize &&
                          <div className='card-details'>
                            <div>
                                <span className='card-heading'>
                                {headings}
                                </span>
                            </div>
                            {state?.monthlyBool ? (
                              <span className = {
                                state?.jobCandidates?.price === priceVal.jobPrice.monthlyPrice  && state?.jobCandidates?.selected ? "selected-value" : "list-button"
                              } onClick={() => handleChange({
                                  price: priceVal.jobPrice.monthlyPrice,
                                  type: 'monthly',
                                  cardType: 'jobCandidates',
                              })}>
                                  {smallSize? 'USD $200/mo,' : mediumSize? 'USD $300/mo,' : 'USD $400/mo,'}
                              </span>
                            ) : (
                              <span className = 'disabled-value'>
                                  {smallSize? 'USD $200/mo,' : mediumSize? 'USD $300/mo,' : 'USD $400/mo,'}
                              </span>
                            )}
                            {state?.yearlyBool ? (
                              <span className = {
                                state?.jobCandidates?.price === priceVal.jobPrice.yearlyPrice && state?.jobCandidates?.selected? "selected-value" : "list-button"
                                } onClick={() => handleChange({
                                  price: priceVal.jobPrice.yearlyPrice,
                                  type: 'yearly',
                                  cardType: 'jobCandidates',
                              })}>
                                  {smallSize? 'USD $2200/yearly' : mediumSize? 'USD $4200/yearly' : 'USD $4300/yearly'}
                              </span>
                            ) : (
                              <span className = 'disabled-value'>
                                  {smallSize? 'USD $2200/yearly' : mediumSize? 'USD $4200/yearly' : 'USD $4300/yearly'}
                              </span>
                            )}
                          </div>
                        }
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} xl={8}>
                    <div className="pricing-card-wrapper" style={{ boxShadow: state?.employPerformance?.selected ? '0px 12px 24px 0px #00000029, 0 0 0 2px #FFFFFF, 0 0 0 4px #29AAE2' : '0px 12px 24px 0px #00000029' }}>
                        <div className='plan'>
                            Employee Performance
                        </div>
                        <div className='list'>
                        {
                          enterpriseSize &&
                          <div className='card-details'>
                              Large Enterprise - Custom
                          </div>
                        }
                        {
                          !enterpriseSize &&
                          <div className='card-details'>
                            <div>
                                <span className='card-heading'>
                                {headings}
                                </span>
                            </div>
                            {state?.monthlyBool ? (
                              <span className = {state?.employPerformance?.price === priceVal.employeePrice.monthlyPrice && state?.employPerformance?.selected ? "selected-value" : "list-button"} onClick={() => handleChange({
                                  price: priceVal.partnerPrice.monthlyPrice,
                                  type: 'monthly',
                                  cardType: 'employPerformance',
                              })}>
                                  {smallSize? 'USD $50/mo,' : mediumSize? 'USD $100/mo,' : 'USD $300/mo,'}
                              </span>
                            ) : (
                              <span className = 'disabled-value'>
                                  {smallSize? 'USD $50/mo,' : mediumSize? 'USD $100/mo,' : 'USD $300/mo,'}
                              </span>
                            )}
                            {state?.yearlyBool ? (
                              <span className = {state?.employPerformance?.price === priceVal.employeePrice.yearlyPrice && state?.employPerformance?.selected ? "selected-value" : "list-button"} onClick={() => handleChange({
                                  price: priceVal.partnerPrice.yearlyPrice,
                                  type: 'yearly',
                                  cardType: 'employPerformance',
                              })}>
                                  {smallSize? 'USD $500/yearly' : mediumSize? 'USD $1000/yearly' : 'USD $3200/yearly'}
                              </span>
                            ) : (
                              <span className = 'disabled-value'>
                                  {smallSize? 'USD $500/yearly' : mediumSize? 'USD $1000/yearly' : 'USD $3200/yearly'}
                              </span>
                            )}
                          </div>
                        }
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12} xl={8}>
                    <div className="pricing-card-wrapper" style={{ boxShadow: state?.partnerPerformance?.selected ? '0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 2px #FFFFFF, 0 0 0 4px #29AAE2' : '0px 12px 24px 0px #00000029' }}>
                        <div className='plan'>
                            Partner Performance
                        </div>
                        <div className='list'>
                            {
                          enterpriseSize &&
                          <div className='card-details'>
                              Large Enterprise - Custom
                          </div>
                        }
                        {
                          !enterpriseSize &&
                          <div className='card-details'>
                            <div>
                                <span className='card-heading'>
                                {headings}
                                </span>
                            </div>
                            {state.monthlyBool? (
                              <span className = {state?.partnerPerformance?.price === priceVal.partnerPrice.monthlyPrice && state?.partnerPerformance?.selected? "selected-value" : "list-button"} onClick={() => handleChange({
                                  price: priceVal.employeePrice.monthlyPrice,
                                  type: 'monthly',
                                  cardType: 'partnerPerformance',
                              })}>
                                  {smallSize? 'USD $50/mo,' : mediumSize? 'USD $100/mo,' : 'USD $300/mo,'}
                              </span>
                            ) : (
                              <span className = 'disabled-value'>
                                  {smallSize? 'USD $50/mo,' : mediumSize? 'USD $100/mo,' : 'USD $300/mo,'}
                              </span>
                            )}
                            {state.yearlyBool? (
                              <span className = {state?.partnerPerformance?.price === priceVal.partnerPrice.yearlyPrice && state?.partnerPerformance?.selected ? "selected-value" : "list-button"} onClick={() => handleChange({
                                  price: priceVal.employeePrice.yearlyPrice,
                                  type: 'yearly',
                                  cardType: 'partnerPerformance',
                              })}>
                                  {smallSize? 'USD $500/yearly' : mediumSize? 'USD $1000/yearly' : 'USD $3200/yearly'}
                              </span>
                            ) : (
                              <span className = 'disabled-value'>
                                  {smallSize? 'USD $500/yearly' : mediumSize? 'USD $1000/yearly' : 'USD $3200/yearly'}
                              </span>
                            )}
                          </div>
                        }
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className='total-price'>
            <div className='button'>
                <Button
                    text = {'Clear'}
                    onClick = {() => setState(initialState)}
                />
            </div>
        </div>
        <div className='total-price'>
            <div className='centered-box'>
                Total = $ {state?.totalPrice}
            </div>
        </div>
        <ToastContainer />
        <div>
            <CompanyInfo/>
        </div>
    </div>
}
