import React from 'react';
import '../assets/scss/pages/partners.scss';

import header_five from '../assets/img/1-Technology Alliance Partner.png';
import header_six from '../assets/img/2-Channel Partner.png';


import PageTop from '../components/common/page-top'
import { 
    LeftImage,
} from '../components/common/imageContent';
import ContactForm from '../components/common/contactForm'
import {CompanyInfo} from '../components/common/companyInfo';

export default function Partners() {
    const paraText = `Do you have a gap in your technology stack in terms of automating assessments?
    <br/>
    HiAcuity would love to become a technology partner with you and deliver high quality 
    joint solutions to end users. Click here to become a technology alliance partner.`

    return <div className="partner-wrapper">
        <PageTop
            title = {"Technology Alliance Partner"}
            paragraph = {paraText}
            buttonsText = {"Learn More"}
            image = {header_five}
        />

        <div className='second-section-wrapper'>
            <LeftImage
                title = {"Channel Partner"}
                paragraph = {`Would you like to be a channel partner for HiAcuity?
                <br/> 
                HiAcuity would love to talk to you about becoming a channel partner for 
                HiAcuity solutions. Click here to become a channel partner.`}
                buttonsText = {"Button Text"}
                image = {header_six}
            />
        </div>

        <div className='third-section-wrapper'>
            <ContactForm
                title={"Let's talk"}
            />
        </div>
        <div>
            <CompanyInfo/>
        </div>
    </div>
}