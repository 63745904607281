import React from 'react';
import '../../assets/scss/pages/subPages/ERPNext.scss';
import { 
    Row, 
} from 'antd';
import {
    IconCard,
} from '../../components/common/cards';
import {CompanyInfo} from '../../components/common/companyInfo';

export default function ERPNext()
{
    const iconCardContent = [
        {
            id:"1",
            cardPara: "Financial Accounting",
            cardButton: "View More",
        },
        {
            id:"2",
            cardPara: "Order Management ",
            cardButton: "View More",
        },
        {
            id:"3",
            cardPara: "CRM",
            cardButton: "View More",
        },
        {
            id:"4",
            cardPara: "HR and Payroll",
            cardButton: "View More",
        },
        {
            id:"5",
            cardPara: "Manufacturing",
            cardButton: "View More",
        },
        {
            id:"6",
            cardPara: "Projects",
            cardButton: "View More",
        },
        {
            id:"7",
            cardPara: "Asset Management ",
            cardButton: "View More",
        },
        {
            id:"8",
            cardPara: "Website",
            cardButton: "View More",
        },
        {
            id:"9",
            cardPara: "Help Desk",
            cardButton: "View More",
        },
    ]

    return <div className="erp-next-wrapper">
        <h1 className='heading-1'>
            ERPNext
        </h1>
        <p className='paragraph'>
        HiAcuity hosts a fully-fledged ERP solution. We do understand that no two organisations 
        are alike and we therefore provide the ability to customise our ERP solution. 
        By engaging with our customers, we tailor our standard solution to meet the unique 
        requirements that are presented to us. This results in organisations being able to 
        deliver outcomes in a highly streamlined manner.
        <br/>
        <br/>
        Our agile ERP comes with a bundle of features that covers everything you need 
        including the below modules:
        </p>
       <div className='second-section-wrapper'>
            <Row>
                {iconCardContent?.map((item) => {
                    return(
                        <IconCard
                            key={item?.id}
                            cardTitle = {item?.cardTitle}
                            cardPara = {item?.cardPara}
                            cardButton = {item?.cardButton}
                            icon = {item?.icon}
                        />
                    )
                })}
            </Row>
        </div>
        <div>
            <CompanyInfo/>
        </div>
    </div>
}