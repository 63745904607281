import React from 'react';
import '../../../assets/scss/components/common/cards/cards.scss';
import { Col } from 'antd';
import {Button} from '../buttons';
import Parser from 'html-react-parser';

export function IconCard({
    cardTitle,
    cardPara,
    cardButton,
    icon,
}) 
{
    return <Col xs={24} sm={12} md={12} xl={6}>
        <div className='icon-wrapper'>
            <img src={icon} alt=''/>
        </div>
        <div className='cards-wrapper'>
            <div className='heading-3'>
                {cardTitle}
            </div>
            <div className='card-para'>
                {cardPara}
            </div>
        </div>
    </Col>
}

export function SliderCard({
    cardTitle,
    cardPara,
    name,
    position,
    profilePic,
}){
    return (
        <>
            <div className='slider-icon-wrapper'>
                <img src={profilePic} alt=''/>
            </div>
            <Col xs={24} sm={12} md={12} xl={6}>
                <div className='slider-cards-wrapper'>
                    <div className='heading-3'>
                        {cardTitle}
                    </div>
                    <div className='card-para'>
                        {cardPara}
                    </div>
                    <div className='card-button'>
                        {name}
                    </div>
                    <div className='card-position'>
                        {position}
                    </div>
                </div>
            </Col>
        </>
    )
}

export function ServiceCard({
    cardTitle,
    cardPara,
    cardButton,
    icon,
}){
    return (
        <>
            <Col xs={24} sm={12} md={8} xl={6}>
                <div className='service-cards-wrapper'>
                    <div className='service-icon-wrapper'>
                        <img src={icon} alt=''/>
                    </div>
                    <div className='heading-3'>
                        {cardTitle}
                    </div>
                    <div className='card-para'>
                        {Parser(cardPara)}
                    </div>
                </div>
            </Col>
        </>
    )
}

export function PricingCard({
    plan,
    monthlyAmount,
    yearlyAmount,
    subSmallHeading,
    list,
    button,
}){
    return (
        <>
            <Col xs={24} sm={24} md={12} xl={8}>
                <div className='pricing-card-wrapper'>
                    <div className='plan'>
                        {plan}
                    </div>
                    <div className='monthlyAmount'>
                        {monthlyAmount}
                    </div>
                    <div className='yearlyAmount'>
                        {yearlyAmount}
                    </div>


                    <div className='subSmallHeading'>
                        {subSmallHeading}
                    </div>
                    <div className='list'>
                        {list?.map((item) => {
                            return(
                                <div>{item}</div>
                            )
                        })}
                    </div>
                    <div className='button'>
                        <Button
                            text = {button}
                        />
                    </div>
                </div>
            </Col>
        </>
    )
}