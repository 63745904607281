import IconOne from '../assets/img/icons/icon-1.png';
import IconTwo from '../assets/img/icons/icon-2.png';
import IconThree from '../assets/img/icons/icon-3.png';
import IconFour from '../assets/img/icons/icon-4.png';
import Mobile from '../assets/img/icons/mobile.png';
import Location from '../assets/img/icons/location.png';
import Email from '../assets/img/icons/email.png';
import BestPractices from '../assets/img/icons/Best Practices.png';
import COEEstablishment from '../assets/img/icons/COE Establishment.png';
import OpportunityAssessment from '../assets/img/icons/Opportunity Assessment.png';
import PracticeEvaluation from '../assets/img/icons/Practice Evaluation.png';
import RPAStaffAugmentation from '../assets/img/icons/RPA Staff Augmentation.png';
import Rpa_Consult from '../assets/img/icons/Rpa_Consult.png';
import Rpa_pilot from '../assets/img/icons/Rpa_pilot.png';
import Rpa_Training from '../assets/img/icons/RPA Training Coruses.png';
import exc from '../assets/img/icons/excellence.png';
import one from '../assets/img/icons/excellence.png';
import oneDown from '../assets/img/icons/excellence.png';
import two from '../assets/img/icons/excellence.png';
import twoDown from '../assets/img/icons/excellence.png';
import three from '../assets/img/icons/excellence.png';
import threeDown from '../assets/img/icons/excellence.png';
import four from '../assets/img/icons/excellence.png';
import fourDown from '../assets/img/icons/excellence.png';
import oneSisco from '../assets/img/icons/5.png';
import twoSisco from '../assets/img/icons/6.png';
import threeSisco from '../assets/img/icons/7.png';
import fourSisco from '../assets/img/icons/8.png';


const Icons = {
    IconOne,
    IconTwo,
    IconThree,
    IconFour,
    Mobile,
    Location,
    Email,
    BestPractices,
    COEEstablishment,
    OpportunityAssessment,
    PracticeEvaluation,
    RPAStaffAugmentation,
    Rpa_Consult,
    Rpa_pilot,
    Rpa_Training,
    exc,
    one,
    oneDown,
    two,
    twoDown,
    three,
    threeDown,
    four,
    fourDown,
    oneSisco,
    twoSisco,
    threeSisco,
    fourSisco
}

export default Icons;