import React from 'react';
import '../assets/scss/pages/about.scss';
import PageTop from '../components/common/page-top'
import header from '../assets/img/location2.png';
import {
    LeftImage,
} from '../components/common/imageContent';
import header_ten from '../assets/img/6-Objective.png';
import { CompanyInfo } from '../components/common/companyInfo';

export default function About() {

    const paraText = `HiAcuity is a start up company with a software engineering team based in Sri Lanka.
    <br/>
    We view ourselves as an automation 
    technology company that can assist customers through our product, consulting and 
    software development capabilities.`

    return <div className="about-wrapper">
        <PageTop
            title={"Background"}
            paragraph={paraText}
            buttonsText={"Learn More"}
            image={header}
        />

        <div className='second-section-wrapper'>
            <LeftImage
                title={"Objective"}
                paragraph={`HiAcuity’s main goal is to help organisations evaluate current 
                employees, potential employees, and partners in a purely objective manner.
                <br/> 
                Using state of the art voice and facial recognition technologies, we will help you 
                assess the competency levels of your staff without adding additional workload 
                on your current employees. Get access to insightful data which you can use to 
                strengthen the quality of your workforce and make informed decisions.`}
                buttonsText={"Button Text"}
                image={header_ten}
            />
        </div>
        <div>
            <CompanyInfo />
        </div>
    </div>
}