import React from 'react';
import '../assets/scss/pages/home.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import header_four from '../assets/img/04.png';
import header_seven from '../assets/img/4-Service.png';

import PageTop from '../components/common/page-top'
import { 
    Row, 
} from 'antd';
import {
    IconCard,
} from '../components/common/cards';
import { 
    RightImage,
} from '../components/common/imageContent';
import Icons from '../const/icons';
import {CompanyInfo} from '../components/common/companyInfo';
export default function Home() {

    const paraText = `HiAcuity is an automation technology and software development company.
    Our platform helps organisations assess the skills of job candidates, employees and partners.
    With the help of our consultants we aid businesses to automate high manual processes through Robotic Process Automation.
    Build your cutting edge applications with help from our talented software development team.
    `

    const iconCardContent = [
        {
            id:"1",
            cardTitle: "Skills Assessment Platform",
            cardPara: `An automated skills assessment platform for all relevant personas that touch your organisation.`,
            cardButton: "View More",
            icon: Icons.IconOne,
        },
        {
            id:"2",
            cardTitle: "RPA Consultancy",
            cardPara: "Experienced RPA Consultants to help establish or improve your RPA Program.",
            cardButton: "View More",
            icon: Icons.IconTwo,
        },
        {
            id:"3",
            cardTitle: "ERPNext",
            cardPara: "Next Generation ERP Solutions.",
            cardButton: "View More",
            icon: Icons.IconThree,
        },
        {
            id:"4",
            cardTitle: "Software Development",
            cardPara: "Highly talented pool of software engineers ready to build your exciting new application.",
            cardButton: "View More",
            icon: Icons.IconFour,
        },
    ]

    return <>
        <div className="home-wrapper">
            <div className="first-section-wrapper">

                <PageTop
                    title = {"Assess, Consult, Develop"}
                    paragraph = {paraText}
                    image = {header_four}
                />

            </div>
            
            <div className='second-section-wrapper'>
                <Row>
                    {iconCardContent?.map((item) => {
                        return(
                            <IconCard
                                key={item?.id}
                                cardTitle = {item?.cardTitle}
                                cardPara = {item?.cardPara}
                                cardButton = {item?.cardButton}
                                icon = {item?.icon}
                            />
                        )
                    })}
                </Row>
            </div>

            <div className='third-section-wrapper'>
                <RightImage
                    title = {"HiAcuity Services"}
                    paragraph = {"HiAcuity provides services that reflects our experience and interests. We are passionate about reducing highly manual and repetitive tasks through the use of Robotic Process Automation."}
                    buttonsText = {"View More"}
                    url = {"/services"}
                    image = {header_seven}
                />
            </div>

            <div>
                <CompanyInfo/>
            </div>
        </div>
    </>
}