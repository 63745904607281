import React from 'react';
import '../assets/scss/pages/services.scss';
import header_seven from '../assets/img/4-Service.png';
import header_eight from '../assets/img/5-RPA.png';
import header_eleven from '../assets/img/7-Software-Development.png';
import PageTop from '../components/common/page-top';
import {
    ServiceCard,
} from '../components/common/cards'
import { Row } from 'antd';
import Icons from '../const/icons';
import {
    LeftImage,
} from '../components/common/imageContent';
import {CompanyInfo} from '../components/common/companyInfo';

export default function Services() {
    const paraText = `HiAcuity provides services that reflects our experience and interests.
    <br/>
     We are passionate about reducing highly manual and repetitive tasks through the use of Robotic Process Automation.`

    const iconCardContent = [
        {
            id: "1",
            cardTitle: "RPA Proof of Concept",
            cardPara: "Run an RPA Proof of Concept (upto 4 weeks)",
            cardButton: "Button Text",
            icon: Icons.Rpa_Consult,
        },
        {
            id: "2",
            cardTitle: "RPA Pilot",
            cardPara: "Conduct an RPA Pilot (upto 3 months)",
            cardButton: "Button Text",
            icon: Icons.Rpa_pilot,
        },
        {
            id: "3",
            cardTitle: "Opportunity Assessment",
            cardPara: "Identify processes within your business that can be automated.",
            cardButton: "Button Text",
            icon: Icons.OpportunityAssessment,
        },
        {
            id: "4",
            cardTitle: "COE Establishment",
            cardPara: "Establish an RPA Centre of Excellence.",
            cardButton: "Button Text",
            icon: Icons.COEEstablishment,
        },
        {
            id: "5",
            cardTitle: "RPA Staff Augmentation",
            cardPara: "Augment your RPA team with HiAcuity BAs, RPA Consultants and RPA Developers.",
            cardButton: "Button Text",
            icon: Icons.RPAStaffAugmentation,
        },
        {
            id: "6",
            cardTitle: "RPA Training Courses",
            cardPara: "Face to face RPA Training courses for various roles and products.",
            cardButton: "Button Text",
            icon: Icons.Rpa_Training,
        },

        {
            id: "7",
            cardTitle: "Best Practices",
            cardPara: "Learn Best Practices for RPA Business Analysts, Developers and Testers.",
            cardButton: "Button Text",
            icon: Icons.BestPractices,
        },
        {
            id: "8",
            cardTitle: "Practice Evaluation",
            cardPara: "Review your RPA Program, Policies, and Automations. ",
            cardButton: "Button Text",
            icon: Icons.PracticeEvaluation,
        },
    ]



    return <div className="services-wrapper">
        <PageTop
            title={"HiAcuity Services"}
            paragraph={paraText}
            buttonsText={"Learn More"}
            image={header_seven}
        />

        <div className='third-section'>
            <LeftImage
                title={"RPA Consulting"}
                paragraph={`Looking to start or improve your RPA practice?
                <br/>
                HiAcuity helps organisations identify highly repetitive business processes and we then build software automations so that human effort can be spent on other higher value tasks.
                <br/>
                With 5 years of experience in RPA, HiAcuity can help you establish an RPA practice within your organisation. We can also review your current practice and provide recommendations for improving it.
                <br/>
                We specialise in UiPath and Microsoft Power Automate Technology Stacks.`}
                buttonsText={"Button Text"}
                image={header_eight}
            />
        </div>

        <div className='second-section'>
            <Row>
                {[...iconCardContent,].map((item) => {
                    return (
                        <>
                            <ServiceCard
                                key={item?.id}
                                cardTitle={item?.cardTitle}
                                cardPara={item?.cardPara}
                                cardButton={item?.cardButton}
                                icon={item?.icon}
                            />
                        </>
                    )
                })}
            </Row>
        </div>

        <div className='fifth-section'>
            <LeftImage
                title={"Software Development"}
                paragraph={`HiAcuity as an organisation has an experienced talent pool 
                that can help you design, build and deliver customised software solutions 
                using a wider variety of technologies. 
                <br/>
                Our company’s strength in software 
                development combined with our deep understanding of business operations and 
                processes allows us to build the application that you want.`}
                buttonsText={"Button Text"}
                image={header_eleven}
            />
        </div>

        <div>
            <CompanyInfo/>
        </div>
    </div>
}
