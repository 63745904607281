import React from 'react';
import '../../../assets/scss/components/common/imageContent/imageContent.scss';
import { Col, Row } from 'antd';
import {Button} from '../buttons';
import Parser from 'html-react-parser';

export function RightImage({
    title,
    paragraph,
    buttonsText = null,
    image,
    url = null,
}) 
{
    const handleButtonClick = () => {
        if(url !== null){
            window.location.href = url;
        }
    };

    return <div className='right-image-wrapper'>
        <Row>
            <Col xs={24} sm={24} md={24} xl={12} className='right-image-right-mobile'>
                <span>
                    <img 
                        src = {image} 
                        alt=""
                    />
                </span>
            </Col>
            <Col xs={24} sm={24} md={24} xl={12}>
                <div className='right-image-left'>
                    <h1 className='heading-1'>
                        {title}
                    </h1>
                    <p className='paragraph'>
                        {Parser(paragraph)}
                    </p>
                    {buttonsText !== null ? 
                        <Button
                            onClick={handleButtonClick}
                            text = {buttonsText}
                        />
                    : null}
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} xl={12} className='right-image-right'>
                <span>
                    <img 
                        src = {image} 
                        alt=""
                    />
                </span>
            </Col>
        </Row>
    </div>
}

export function LeftImage({
    title,
    paragraph,
    buttonsText,
    image,
}) 
{
    return  <div className='left-image-wrapper'>
        <Row>
            <Col xs={24} sm={24} md={24} xl={12} className='left-image-left'>
                <span>
                    <img 
                        src = {image} 
                        alt=""
                    />
                </span>
            </Col>
            <Col xs={24} sm={24} md={24} xl={12}>
                <div className='left-image-right'>
                    <h1 className='heading-1'>
                        {title}
                    </h1>
                    <p className='paragraph'>
                    {Parser(paragraph)}
                    </p>
                </div>
            </Col>
        </Row>
    </div>
}