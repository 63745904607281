import { useRef, useState, useEffect } from "react";
import { FaBars } from 'react-icons/fa'; 
import "../../assets/scss/components/navbar/navbar.scss";
import logoImage from "../../assets/img/logo Main 2.png";

const Navbar = () => {
  const navRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const navLinks = navRef.current.querySelectorAll("a");

    navLinks.forEach((link) => {
      if (link.getAttribute("href") === currentPath && currentPath !== "/contact") {
        link.classList.add("current-link");
      } else {
        link.classList.remove("current-link");
      }
    });
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/">
          <img src={logoImage} alt="Logo" />
        </a>
      </div>
      <ul ref={navRef} className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <li>
          <a href="/about">About Us</a>
        </li>
        <li>
          <a href="/services">Services</a>
        </li>

        <li className="display-mobile-only">
          <a  href="/siso" >Skills Assessment</a>
        </li>
        <li className="display-mobile-only">
          <a href="/ERPNext">ERPNext</a>
        </li>

        <li className="mobile-view">
          <span class="solution"  onClick={toggleDropdown}>
            Solutions
          </span>
          {showDropdown && (
            <ul className="dropdown-menu">
              <li>
                <a 
                  href="/siso" 
                  className={window.location.pathname === "/siso" ? "current-link" : ""}
                >Skills Assessment</a>
              </li>
              <li>
                <a 
                  href="/ERPNext" 
                  className={window.location.pathname === "/ERPNext" ? "current-link" : ""}
                >ERPNext</a>
              </li>
            </ul>
          )}
        </li>
        
        <li>
          <a href="/pricing">Pricing</a>
        </li>
        <li>
          <a href="/partners">Partners</a>
        </li>
        <li>
          <a href="/contact">
            <span className="nav-btn-red">Contact Us</span>
          </a>
        </li>
      </ul>
      <div className="navbar-toggle" onClick={toggleMenu}>
        <FaBars />
      </div>
    </nav>
  );
};

export default Navbar;
