import React from 'react';
import '../../../assets/scss/components/common/buttons/buttons.scss';

export function Button({
    text,
    onClick,
}) 
{
    return <div className="global-button-style" onClick = {onClick}>
        {text}
    </div>
}