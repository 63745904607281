import React from 'react';
import '../../../assets/scss/components/common/companyInfo/companyInfo.scss';
import { Col, Row } from 'antd';
import Icons from '../../../const/icons'

export function CompanyInfo() {

    const mailOpen = () => {
        const recipientEmail = 'sales@hiacuity.com';
        const mailtoLink = `mailto:${recipientEmail}?`;
        window.location.href = mailtoLink;
    };

    return <div className='contact-bottom-section'>
        <Row>
{/*             <Col xs={24} sm={24} md={12} xl={8}> */}
{/*                 <Row> */}
{/*                     <Col xs={4}> */}
{/*                         <img src={Icons.Mobile} alt=''/> */}
{/*                     </Col> */}
{/*                     <Col xs={20}> */}
{/*                         <div className='title'> */}
{/*                             Phone Number */}
{/*                         </div> */}
{/*                         <div> */}
{/*                             + 61 400 144 601 */}
{/*                         </div> */}
{/*                     </Col> */}
{/*                 </Row> */}
{/*             </Col> */}

            <Col  xs={24} sm={24} md={12} xl={8}>
                <Row>
                    <Col xs={4}>
                        <img src={Icons.Location} alt=''/>
                    </Col>
                    <Col xs={20}>
                        <div className='title'>
                            Location
                        </div>
                        <div>
                            Colombo
                        </div>
                    </Col>
                </Row>
            </Col>

            <Col xs={24} sm={24} md={12} xl={8}>
                <Row className='email-open' onClick={mailOpen}>
                    <Col xs={4}>
                        <img src={Icons.Email} alt=''/>
                    </Col>
                    <Col xs={20}>
                        <div className='title'>
                            Email
                        </div>
                        <div>
                            sales@hiacuity.com
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}