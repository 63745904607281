import React, { useState } from 'react';
import '../../../assets/scss/components/common/contactForm/contactForm.scss';
import { Col, Row } from 'antd';
import {Button} from '../buttons';
import FormImage from '../../../assets/img/3-Let us talk.png'
import { ToastContainer, toast } from 'react-toastify';
import { 
    InputField, 
    TextAreaField 
} from '../input'
import Icons from '../../../const/icons'
import ReCAPTCHA from 'react-google-recaptcha';

export default function ContactForm({
    title,
    paragraph,
    buttonsText,
    image,
}) 
{
    const apiKey = process.env.REACT_APP_SECRET_KEY;
    const [isVerified, setIsVerified] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const [formData, setFormData] = useState({
      first_name: '',
      last_name: '',
      email: '',
      country: '',
      company_name: '',
      industry: '',
      description: ''
    });

    const handleVerification = (response) => {
        if (response) {
          setIsVerified(true);
          setCaptchaToken(response)
        }
    };

    const handleInputChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = () => {
        if (isVerified) {
           if (formData.first_name==='' || formData.last_name==='' || formData.email===''){
               toast.error("please fill in your name and email");
           }
           else{
               const url = 'https://backend-api.dev.hiacuity.com/external/web/contact'
               const body = {
                 captchaToken: captchaToken,
                 firstName: formData.first_name,
                 lastName: formData.last_name,
                 email: formData.email,
                 country: formData.country,
                 companyName: formData.company_name,
                 industry: formData.industry,
                 description: formData.description,
               };

               fetch(url, {
                  method: "POST",
                  headers: {
                    'Content-type': 'application/json'
                  },
                  body: JSON.stringify(body)
                })
                .then((response) => response.json())
                .then((result) => {
                  if (result.status==='fail'){
                    toast.error('Your message failed to send');
                  }
                  else{
                    toast.success('Your message has been sent!');
                  }
                })
           }
        } else {
          alert('Please complete the reCAPTCHA verification.');
        }
    };

    return <div className='contact-form-wrapper'>
        <h1 className='heading-2'>
            {title}
        </h1>
        <Row>
            <Col xs={24} sm={24} md={24} xl={12}>
                <p className='paragraph'>
                    Need to get in touch with us? Either fill the form for inquiry  or
                    find the department, email, phone you’d like to contact below.
                </p>
                <div className='image-wrapper'>
                    <img src={FormImage} alt=''/>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} xl={12}>
                <h3 className='heading-3'>
                    Send Us a Message
                </h3>
                <div className='form-wrapper'>
                    <Row>
                        <Col xs={12}>
                            <InputField
                                placeholder='First name'
                                value={formData.first_name}
                                onChange={(e) => handleInputChange('first_name', e.target.value)}
                            />
                        </Col>
                        <Col xs={12}>
                            <InputField
                                placeholder='Last name'
                                value={formData.last_name}
                                onChange={(e) => handleInputChange('last_name', e.target.value)}
                            />
                        </Col>
                        <Col xs={12}>
                            <InputField
                                placeholder='Email'
                                value={formData.email}
                                onChange={(e) => handleInputChange('email', e.target.value)}
                            />
                        </Col>
                        <Col xs={12}>
                            <InputField
                                placeholder='Select country'
                                value={formData.country}
                                onChange={(e) => handleInputChange('country', e.target.value)}
                            />
                        </Col>
                        <Col xs={12}>
                            <InputField
                                placeholder='Company name'
                                value={formData.company_name}
                                onChange={(e) => handleInputChange('company_name', e.target.value)}
                            />
                        </Col>
                        <Col xs={12}>
                            <InputField
                                placeholder='Select Industry'
                                value={formData.industry}
                                onChange={(e) => handleInputChange('industry', e.target.value)}
                            />
                        </Col>
                        <Col xs={24}>
                            <TextAreaField
                                placeholder='Description'
                                value={formData.description}
                                onChange={(e) => handleInputChange('description', e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
                <div className='button-wrapper'>
                    <ReCAPTCHA
                        sitekey={apiKey}
                        onChange={handleVerification}
                    />
                    <div className='contact-button-wrapper'>
                    <Button
                        text = {'Contact us'}
                        onClick = {handleSubmit}
                    />
                    </div>
                </div>
            </Col>
        </Row>
        <ToastContainer />
    </div>
}