import React from 'react';
import '../../assets/scss/pages/subPages/siso.scss';
import { 
    Row, Col
} from 'antd';
import {
    IconCard,
} from '../../components/common/cards';
import PageTop from '../../components/common/page-top';
import Icons from '../../const/icons';
import { 
    RightImage,
} from '../../components/common/imageContent';
import { Button } from '../../components/common/buttons'
import {CompanyInfo} from '../../components/common/companyInfo';
import hiAcuityEmployerOne from '../../assets/img/siso/HiAcuity_Employer-Assessment_1.png';
import hiAcuityJobOne from '../../assets/img/siso/HiAcuity_Job-Candidate_1.png';
import hiAcuityPartner from '../../assets/img/siso/HiAcuity_Partner-Assessment.png';
import hiAcuitySkill from '../../assets/img/siso/HiAcuity_Skill-Assessment-Program.png';

import iconOne from '../../assets/img/Platform Features.png';
import iconTwo from '../../assets/img/AI Scoring.png';
import iconThree from '../../assets/img/fraud-detection.png';
import iconFour from '../../assets/img/Questions bank.png';
import iconFive from '../../assets/img/Analytics.png';
import iconSix from '../../assets/img/Hiararchy.png';



export default function SISO() {

    const paraText = `The SISO Skills Assessment is an AI based SaaS Solution
    <br/>
    That can be used to automatically assess the skills of
    <br/>
    your employees, partners and job candidates.`

    const iconCardContent = [
        {
            id:"1",
            cardTitle: "Objective Assessments",
            cardButton: "View More",
            icon: Icons.oneSisco,
        },
        {
            id:"2",
            cardTitle: "Actionable Insights",
            cardButton: "View More",
            icon: Icons.twoSisco,
        },
        {
            id:"3",
            cardTitle: "Reward for Measurable Performance",
            cardButton: "View More",
            icon: Icons.threeSisco,
        },
        {
            id:"4",
            cardTitle: "Speed and Efficiency",
            cardButton: "View More",
            icon: Icons.fourSisco,
        },
    ]

    const handleDownload = () => {
        window.open('/brochure2.pdf', '_blank');
    }

    return <>
        <div className='siso-wrapper'>
            <div className='first-section-wrapper'>
                <PageTop
                    title = {"SISO  Skills Assessment Platform"}
                    paragraph = {paraText}
                    buttonsText = {"Learn More"}
                    image = {hiAcuitySkill}
                />
            </div>

            <div className='second-section-wrapper'>
                <Row>
                    {iconCardContent?.map((item) => {
                        return(
                            <IconCard
                                key={item?.id}
                                cardTitle = {item?.cardTitle}
                                cardPara = {item?.cardPara}
                                cardButton = {item?.cardButton}
                                icon = {item?.icon}
                            />
                        )
                    })}
                </Row>
            </div>

            <div className='third-section-wrapper'>
                <h1 className='heading-1'>
                    Platform Features
                </h1>
                <div className='content-list-wrapper'>
                    <Row>
                        <Col xs={24} sm={12} md={12} xl={8}>
                            <h1 className='heading-3'>
                                Cloud 
                            </h1>
                            <div className='product-image-wrapper'>
                                <img 
                                    src = {iconOne} 
                                    alt=""
                                />
                            </div>
                        </Col>

                        <Col xs={24} sm={12} md={12} xl={8}>
                            <h1 className='heading-3'>
                                AI Scoring
                            </h1>
                            <div className='product-image-wrapper'>
                                <img 
                                    src = {iconTwo} 
                                    alt=""
                                />
                            </div>
                        </Col>

                        <Col xs={24} sm={12} md={12} xl={8}>
                            <h1 className='heading-3'>
                                Fraud Detection
                            </h1>
                            <div className='product-image-wrapper'>
                                <img 
                                    src = {iconThree} 
                                    alt=""
                                />
                            </div>
                        </Col>

                        <Col xs={24} sm={12} md={12} xl={8}>
                            <h1 className='heading-3'>
                                Question Bank
                            </h1>
                            <div className='product-image-wrapper'>
                                <img 
                                    src = {iconFour} 
                                    alt=""
                                />
                            </div>
                        </Col>

                        <Col xs={24} sm={12} md={12} xl={8}>
                            <h1 className='heading-3'>
                                Analytics
                            </h1>
                            <div className='product-image-wrapper'>
                                <img 
                                    src = {iconFive} 
                                    alt=""
                                />
                            </div>
                        </Col>

                        <Col xs={24} sm={12} md={12} xl={8}>
                            <h1 className='heading-3'>
                                Org Hierarchy
                            </h1>
                            <div className='product-image-wrapper'>
                                <img 
                                    src = {iconSix} 
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className='button-wrapper'>
                        <Button 
                            text={"Learn More"}
                            onClick={handleDownload}
                        />
                    </div>
                </div>
            </div>

            <div className='siso-common-section-wrapper'>
                <h1 className='heading-1'>
                    Job Candidate Assessments
                </h1>

                <h1 className='heading-3'>
                    No time to interview job candidates?
                </h1>

                <div className='image-content-wrapper'>
                    <RightImage
                        title = {"Use SISO to assess every single applicant"}
                        paragraph = {`<ul className='normal-list-wrapper'>
                        <li>Speed up the hiring process.</li>
                        <li>Eliminate low quality candidates.</li>
                        <li>Eliminate cheating inherent in classical online assessments.</li>
                        </ul>`}
                        image = {hiAcuityJobOne}
                    />
                </div>
            </div>

            <div className='siso-common-section-wrapper'>
                <h1 className='heading-1'>
                    Employee Assessments
                </h1>

                <h1 className='heading-3'>
                    Need to assess employees fast, efficient without bias?
                </h1>

                <div className='image-content-wrapper'>
                    <RightImage
                        title = {"Assess all employees with minimum effort"}
                        paragraph = {`<ul className='normal-list-wrapper'>
                        <li>Consistent and fair assessments.</li>
                        <li>Eliminate any perceived bias with human assessments.</li>
                        <li>Ideal for annual performance review, regular skills and performance improvement plans.</li>
                        </ul>`}
                        image = {hiAcuityEmployerOne}
                    />
                </div>
            </div>

            <div className='siso-common-section-wrapper'>
                <h1 className='heading-1'>
                    Partner Assessments
                </h1>

                <h1 className='heading-3'>
                    Want to work with the most skilful partners possible?
                </h1>

                <div className='image-content-wrapper'>
                    <RightImage
                        title = {"Use SISO to assess every single partner"}
                        paragraph = {`<ul className='normal-list-wrapper'>
                        <li>Avoid poor quality work by vetting skill levels of all partner employees before starting on critical business projects.</li>
                        <li>Compare partners by overall skill competency.</li>
                        <li>Partners can also prove their capability without having to undertake unnecessary long online training courses.</li>
                        </ul>`}
                        image = {hiAcuityPartner}
                    />
                </div>
            </div>

            <div>
                <CompanyInfo/>
            </div>
        </div>
    </>
}