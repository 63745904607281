import React from 'react';
import '../../assets/scss/components/footer/footer.scss';
import logoImage from "../../assets/img/logo Main 2.png";
import { BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs';

export default function Footer() {
    return (
        <div className="footer-wrapper">
            <div className="footer-upper">
                <div className="footer-section footer-left">
                    <img src={logoImage} alt="Logo" className="logo-image" />
                </div>
                <div className="footer-section footer-middle">
                    ©2021-2023 HiAcuity Pty Ltd.
                </div>
                <div className="footer-section footer-right">
                    <a href="https://www.facebook.com/hiacuity.au/" target="_blank" rel="noopener noreferrer">
                        <BsFacebook className="facebook-icon" />
                    </a>
                    <a href="https://instagram.com/hiacuity.au?igshid=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer">
                        < BsInstagram className="facebook-icon" />
                    </a>
                    <a href="https://www.linkedin.com/company/hiacuity/" target="_blank" rel="noopener noreferrer">
                        <BsLinkedin className="facebook-icon" />
                    </a>
                </div>
            </div>
            <div className="footer-divider"></div>
            <div className="footer-lower">
                <div className="footer-horizontal-section">
                    <a href="/services">Services</a>
                    <a href="/about">About Us</a>
                    <a href="/pricing">Pricing</a>
                    <a href="/partners">Partner</a>
                </div>
            </div>
        </div>
    );
}
