import React from 'react';
import '../../../assets/scss/components/common/page-top/page-top.scss';
import { Col, Row } from 'antd';
import Parser from 'html-react-parser';

export default function PageTop({
    title,
    paragraph,
    buttonsText,
    image,
}) 
{
    return <div className='top-section'>
        <Row>
            <Col xs={24} sm={24} md={24} xl={12} className='page-top-right-mobile'> 
                <span>
                    <img 
                        src = {image} 
                        alt=""
                    />
                </span>
            </Col>
            <Col xs={24} sm={24} md={24} xl={12}>
                <div className='page-top-left'>
                    <h1 className='heading-1'>
                        {title}
                    </h1>
                    <p className='paragraph'>
                        {Parser(paragraph)}
                    </p>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} xl={12} className='page-top-right'> 
                <span>
                    <img 
                        src = {image} 
                        alt=""
                    />
                </span>
            </Col>
        </Row>
    </div>
}