import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from "../components/navbar";
import Footer from "../components/footer";
import Home from "./home.jsx";
import About from "./about.jsx";
import Partners from "./partners.jsx";
import Services from "./services.jsx";
import Pricing from "./pricing.jsx";
import Contact from "./contact.jsx";
import ERPNext from "./subPages/ERPNext.jsx"
import SISO from "./subPages/siso.jsx"

function App() {
  return (
    <div className="App">
      <div>
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path={"/about"} element={<About />} />
            <Route path={"/partners"} element={<Partners />} />
            <Route path={"/services"} element={<Services />} />
            <Route path={"/pricing"} element={<Pricing />} />
            <Route path={"/contact"} element={<Contact />} />
            <Route path={"/siso"} element={<SISO />} />
            <Route path={"/ERPNext"} element={<ERPNext />} />
          </Routes>
        </Router>
      </div>

      <div>
        <Footer/>
      </div>
    </div>
  );
}

export default App;