import React from 'react';
import '../assets/scss/pages/contact.scss';
import ContactForm from '../components/common/contactForm'
import { BsFacebook, BsInstagram, BsLinkedin } from 'react-icons/bs';
import {CompanyInfo} from '../components/common/companyInfo';

export default function Contact() 
{
    return <>
        <div className="contact-wrapper">
            <div className='heading-2'>
                Check us now
            </div>
            <div className="social-icons">
                <a href="https://www.facebook.com/hiacuity.au/" target="_blank" rel="noopener noreferrer" >
                    <BsFacebook className="social-icon" />
                </a>
                <a href="https://instagram.com/hiacuity.au?igshid=OGQ5ZDc2ODk2ZA=="  target="_blank" rel="noopener noreferrer">
                    <BsInstagram className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/company/hiacuity/"  target="_blank" rel="noopener noreferrer">
                    <BsLinkedin className="social-icon" />
                </a>
            </div>

            <div className='third-section-wrapper'>
                <ContactForm/>
            </div>
        </div>
        <div className='contact-bottom'>
            <CompanyInfo/>
        </div>
    </>
}