import React from 'react';
import '../../../assets/scss/components/common/input/input.scss';
import { Input } from 'antd';

const { TextArea } = Input;

export function InputField({
    placeholder,
    onChange,
    value,
}) 
{
    return <div className='input-form-style'>
        <Input
            placeholder = {placeholder}
            onChange={onChange}
            value={value}
        />
    </div>
}

export function TextAreaField({
    placeholder,
    onChange,
    value
}) 
{
    return <div className='input-form-style'>
        <TextArea
            rows={4}
            placeholder = {placeholder}
            onChange={onChange}
            value={value}
        />
    </div>
}